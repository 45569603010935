import api from "./api";

class ArticleService {
 
  loadArticle() {
    return api.get("/article")
      .then(response => {
  
        return response.data;
      })
  }
  loadFootprint() {
    return api.get("/footprint")
      .then(response => {
    
        return response.data;
      })
  }

}

export default new ArticleService();