
import api from "./api2";


class TwitterService {

  upload(matter) {
    let formData = new FormData();

    formData.append("file", matter);
    
    return api
      .post("/aws/upload", formData, 
      {
        headers: {
          "Content-Type": "multipart/form-data" }

      })
      .then(response => {
        return response.data;
      });
  }
  generate(matter) {
    return api
      .post("/nft/generate", matter)
      .then(response => {
        return response.data;
      });
  }
  get(fileUrl) {
    // use axios to retrieve image file from fileUrl
    return api.get(fileUrl, {
      responseType: 'arraybuffer'
    });

  }
  setQuery(query) {
    return api
      .post("/twitter/query", query)
      .then(response => {
        return response.data;
      });
  }
  getTwitt() {
    return api
      .get("/twitter/twitt")
      .then(response => {
        return response.data;
      });
  }

  editQuery(query) {
    return api
      .post("/twitter/editquery", query)
      .then(response => {
        return response.data;
      });
  }

  deleteQuery(query) {
    return api
      .post("/twitter/deletequery", query)
      .then(response => {
        return response.data;
      });
  }
  listTweets() {
    return api
      .get("/twitter/tweets")
      .then(response => {
        return response.data;
      });
  }
  getAllTweets() {
    return api
      .get("/twitter/all")
      .then(response => {
        return response.data;
      });
  }
  deleteAllTweets() {
    return api
      .delete("/twitter/deleteall")
      .then(response => {
        return response.data;
      });
  }
  manualShill() {
    return api
      .post("/twitter/manualshill")
      .then(response => {
        return response.data;
      });
  }
  shill(data) {
    return api
      .post("/twitter/shill", data)
      .then(response => {
        return response.data;
      });
  }
  getUserTweets() {
    return api
      .get("/twitter/user")
      .then(response => {
        return response.data;
      });
  }
  detectMention(data) {
    return api
      .post("/twitter/detect", data)
      .then(response => {
        return response.data;
      });
  }
  retweetFavorite(data) {
    return api
      .post("/twitter/retweetfavorite", data)
      .then(response => {
        return response.data;
      });
  }
  getAITweets() {
    return api
      .get("/twitter/aitweets")
      .then(response => {
        return response.data;
      });
  }
  getTwitterPrivateMessages(data) {
    return api
      .post("/twitter/messages", data)
      .then(response => {
        return response.data;
      });
  }
  aiPredict(data) {
    return api
      .post("/twitter/predict", data)
      .then(response => {
        return response.data;
      });
  }
  getShillTweets() {
    return api
      .get("/twitter/shilltweets")
      .then(response => {
        return response.data;
      });
  }

  getFollowers() {
    return api
      .get("/twitter/followers")
      .then(response => {
        return response.data;
      });
  }
  whitelistFriend(data) {
    return api
      .post("/twitter/whitelist", data)
      .then(response => {
        return response.data;
      });
  }

  transferTweet(data) {
    return api
      .post("/twitter/transfer", data)
      .then(response => {
        return response.data;
      });
  }

  getWhitelistFriends() {
    return api
      .get("/twitter/whitelist")
      .then(response => {
        return response.data;
      });
  }

  resetwhitelist() {
    return api
      .post("/twitter/resetwhitelist")
      .then(response => {
        return response.data;
      });
  }

  clearShillTweets() {
    return api
      .delete("/twitter/clearshilltweets")
      .then(response => {
        return response.data;
      });
  }

  clearAITweets() {
    return api
      .delete("/twitter/clearaitweets")
      .then(response => {
        return response.data;
      });
  }

  setAutoTweet(data) {
    return api
      .post("/twitter/setautotweet", data)
      .then(response => {
        return response.data;
      });
  }

  getAutoTweet() {
    return api
      .get("/twitter/getautotweet")
      .then(response => {
        return response.data;
      });
  }

  updateAutoTweet(data) {
    return api
      .post("/twitter/updateautotweet", data)
      .then(response => {
        return response.data;
      });
  }

  deleteAutoTweet(data) {
    return api
      .post("/twitter/deleteautotweet", data)
      .then(response => {
        return response.data;
      });
  }

  startAutoTweet(data) {
    return api
      .post("/twitter/startautotweet", data)
      .then(response => {
        return response.data;
      });
  }

  emergencyStop() {
    return api
      .post("/twitter/emergencystop")
      .then(response => {
        return response.data;
      });
  }

}

export default new TwitterService();