import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Hub from '../views/Hub.vue';
import Twitter from '../views/Twitter.vue';
import Notes from '../views/Notes.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/home',
      component: Home
    },
    {
      path: '/hub',
      name: 'hub',
      component: Hub,
      meta: { requiresAuth: true }
    },
    {
      path: '/twitter',
      name: 'twitter',
      component: Twitter,
      meta: {
        requiresAuth: true,
        apiAuth: true
      }
    },
    {
      path: '/notes',
      name: 'notes',
      component: Notes,
      meta: {
        requiresAuth: true,
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  //const publicPages = ['/login', '/register', '/', '/meta', '/home', '/matter', '/life'];
  const authRequired = to.matched.some(record => record.meta.requiresAuth);
  const apiAuthRequired = to.matched.some(record => record.meta.apiAuth);
  const loggedIn = localStorage.getItem('hash') !== '';
  const currentUser = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
  } else if (apiAuthRequired && !currentUser) {
    next('/');
  } else {
    next();
  }
});

export default router
