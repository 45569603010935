import api from "./api";

class AiService {
  create(data) {
    return api
      .post("/ai", {
        prompt: data.prompt,
      })
      .then(response => {
        return response.data;
      });
  }
  complete(data) {
    return api
      .post("/codex", {
        prompt: data.prompt,
      })
      .then(response => {
        return response.data;
      });
  }
  code(data) {
    return api
      .post("/complete", {
        prompt: data.prompt,
      })
      .then(response => {
        return response.data;
      });
  }
  
  
}



export default new AiService();