import api from "./api";

class MediaService {
  loadMedia() {
    return api.get("/media")
      .then(response => {
        return response.data;
      })
  }
  loadFootprint() {
    return api.get("/footprint")
      .then(response => {
 
        return response.data;
      })
  }
 

}

export default new MediaService();