import TwitterService from '../services/twitter.service';
require('dotenv').config();


export const twitter = {
  namespaced: true,
  state: {
    walletAddress: '',
    imageUrl: '',
    message: '',
    attributes: [],
    tweets: [],
    userTweets: [],
    aiTweets: [],
    shillTweets: [],
  },
  actions: {
    uploadImg({ commit }, payload) {
      return TwitterService.upload(payload).then(
        data => {
          commit('uploadImg', data);
          commit('nft/setMessage', "Upload Success", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Upload failed", { root: true })
          return Promise.reject(error);
        }
      );
    },
         

    async setQuery ({ commit }, payload) {
      TwitterService.setQuery(payload).then(
        data => {
          commit('nft/setMessage', "Query tweet sucessfully set", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query tweet setting fail", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async editQuery ({ commit }, payload) {
      TwitterService.editQuery(payload).then(
        data => {
          commit('nft/setMessage', "Query Edit Success", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query Edit Fail", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async deleteQuery ({ commit }, payload) {
      TwitterService.deleteQuery(payload).then(
        data => {
          commit('nft/setMessage', "Query Deleted", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query Deleting Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async listTweets ({ commit, dispatch }) {
      TwitterService.listTweets().then(
        data => {
          commit('nft/setMessage', "Query Success", { root: true })
          dispatch('getAllTweets');
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async getUserTweets ({ commit }) {
      TwitterService.getUserTweets().then(
        data => {
          commit('getUserTweets', data);
          commit('nft/setMessage', "Retrieve User Tweet Success", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Failed Retrieving User Tweets", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async getAllTweets ({ commit }) {
      TwitterService.getAllTweets().then(
        data => {
          commit('getAllTweets', data);
          commit('nft/setMessage', "Query All tweets success", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query all tweets failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async transferTweet ({ commit }, payload) {
      TwitterService.transferTweet(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Tweet transfer failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async resetWhitelist ({ commit }) {
      TwitterService.resetwhitelist().then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Reset failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async clearShillTweets ({ commit }) {
      TwitterService.clearShillTweets().then(
        data => {
          commit('clearShillTweets')
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Clear Shill Tweet failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async clearAITweets ({ commit }) {
      TwitterService.clearAITweets().then(
        data => {
          commit('clearAITweets')
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Clear AI Tweets failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async emergencyStop ({ commit }) {
      TwitterService.emergencyStop().then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Emergency failure, Contact Support!", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async deleteAllTweets ({ commit }) {
      TwitterService.deleteAllTweets().then(
        data => {
          commit('deleteAllTweets');
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Delete all tweets failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async getTwitterPrivateMessages ({ commit }, payload) {
      TwitterService.getTwitterPrivateMessages(payload).then(
        data => {
          // commit('getTwitterPrivateMessages', data);
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async aiPredict ({ commit }, payload) {
      TwitterService.aiPredict(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async getShillTweets ({ commit }, payload) {
      TwitterService.getShillTweets(payload).then(
        data => {
          commit('aiPredict', data);
          commit('nft/setMessage', "Query shill Tweets Success", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query shill Tweets Failed")
          return Promise.reject(error);
        }
      );
    },

    whitelistFriend({ commit }, payload) {
      TwitterService.whitelistFriend(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Whitelisting Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async manualShill ({ commit }) {
      TwitterService.manualShill().then(
        data => {
          commit('nft/setMessage', "Manually Shilled", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Manual Shill Failed")
          return Promise.reject(error);
        }
      );
    },

    async shill({ commit }, payload) {
      TwitterService.shill(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Shill failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

   async detectMention({ commit }, payload) {
      TwitterService.detectMention(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Detecting mentions failed", { root: true })
          return Promise.reject(error);
        }
      );
    },
    async retweetFavorite({ commit }, payload) {
      TwitterService.retweetFavorite(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Retweet Fav Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },
    async getAITweets({ commit }, payload) {
      TwitterService.getAITweets(payload).then(
        data => {
          commit('getAITweets', data);
          commit('nft/setMessage', "Query AI tweet Success", { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Query AI tweet Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },
    
    async setAutoTweet({ commit }, payload) {
      TwitterService.setAutoTweet(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Set Auto Tweet Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async updateAutoTweet({ commit }, payload) {
      TwitterService.updateAutoTweet(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Update Auto Tweet Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async deleteAutoTweet({ commit }, payload) {
      TwitterService.deleteAutoTweet(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Delete Auto Tweet Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async startAutoTweet({ commit }, payload) {
      TwitterService.startAutoTweet(payload).then(
        data => {
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          commit('nft/setMessage', "Start Auto Tweet Failed", { root: true })
          return Promise.reject(error);
        }
      );
    },


  },
  mutations: {
    uploadImg(state, payload) {
      state.imageUrl = payload.ipfsHash;
    },
    getAllTweets(state, payload) {
      state.tweets = payload;
    },
    getUserTweets(state, payload) {
      state.userTweets = payload;
    },
    getAITweets(state, payload) {
      state.aiTweets = payload;
    },
    aiPredict(state, payload) {
      state.shillTweets = payload;
    },
    connectWallet(state, payload) {
      state.walletAddress = payload;
    },
    deleteAllTweets(state) {
      state.tweets = [];
    },
    clearShillTweets(state) {
      state.shillTweets = [];
    },
    clearAITweets(state) {
      state.aiTweets = [];
    }

  },
  getters: {
    loadedImgUrl(state) {
      //return state.loadedMatter.reverse()
      return state.imageUrl;
    },
    getTweets(state) {
      return state.tweets.reverse();
    },
    getAITweets(state) {
      return state.aiTweets;
    },
    getUserTweets(state) {
      return state.userTweets;
    },
    getShillTweets(state) {
      return state.shillTweets;
    },
    loadedWallet(state) {
      return state.walletAddress;
    },
  }
};