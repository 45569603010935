<template>
    <div class="container text-dark">
        <div class="top">
            <h4>Twitter - {{ currentUser.username }}</h4>
        </div>
        <b-row>
            <b-col cols="3">
                <b-button v-b-toggle.collapse-4 variant="nature1" size="sm">
                    <b-icon v-if="settingsVisible" icon="chevron-up"></b-icon>
                    <b-icon v-else icon="chevron-down"></b-icon> Settings
                </b-button>
            </b-col>
            <b-col cols="3">
                <b-button v-b-toggle.collapse variant="nature1" size="sm">
                    <b-icon v-if="retrieveVisible" icon="chevron-up"></b-icon>
                    <b-icon v-else icon="chevron-down"></b-icon> Fetched Tweets ({{ tweets.length }})
                </b-button>
            </b-col>
            <b-col cols="3">

                <b-button v-b-toggle.collapse-5 variant="nature1" size="sm">
                    <b-icon v-if="shillVisible" icon="chevron-up"></b-icon>
                    <b-icon v-else icon="chevron-down"></b-icon>Shill tweets ({{ shillTweets.length }})
                </b-button>
            </b-col>
            <b-col cols="3">
                <b-button v-b-toggle.collapse-7 variant="nature1" size="sm">
                    <b-icon v-if="autoVisible" icon="chevron-up"></b-icon>
                    <b-icon v-else icon="chevron-down"></b-icon> Auto Tweets ({{ autoTweet.length }})
                </b-button>
            </b-col>
        </b-row>
        <b-collapse id="collapse-4" class="mt-2" v-model="settingsVisible">
            <b-row>
                <b-col class="" lg="4" md="4" sm="4" xs="6">
                    <b-row>
                        <b-col cols="6">
                            <TwitterCredentialModal></TwitterCredentialModal>
                        </b-col>
                        <b-col cols="6">
                            <AutoTweet class="mt-2"></AutoTweet>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <SetTwitQuery v-if="!queryFilled"></SetTwitQuery>
                            <EditQueryModal v-else></EditQueryModal>
                        </b-col>
                        <b-col cols="6">
                            <DeleteModal class="mt-2" v-if="queryFilled"></DeleteModal>
                        </b-col>
                    </b-row>


                </b-col>
                <b-col class="" lg="4" md="4" sm="4" xs="6">
                    <div>
                        <b>Query:</b> {{ query.substring(0, 16) }}...

                    </div>
                    <div>
                        <b>delay:</b> {{ delay }} ms

                    </div>

                    <div>
                        <b>Fetched:</b> {{ tweets.length }}
                    </div>

                    <div>
                        <b>Analyzed:</b> {{ shillTweets.length }}
                    </div>

                    <div>
                        <b>Processed:</b> {{ aiTweets.length }}
                    </div>
                </b-col>

                <b-col lg="4" md="4" sm="4" xs="6">

                    <p><b>Whitelisted friends</b></p>

                    <span v-for="(friend, index) in whitelist" :key="index">{{ friend.name }}, </span>

                    <Whitelist class="mt-2" variant="nature4" @whitelistAddress="whitelistAddress" :data="followers">
                    </Whitelist>
                    <b-button class="mt-2" variant="danger" @click="resetwhitelist" size="sm">Reset WhiteList</b-button>

                </b-col>
            </b-row>



            <div class="mt-4 text-dark">

                <b-row>
                    <b-col cols="4">
                        <h4>Tweet options</h4>

                        <b-button v-if="queryFilled" variant="nature1" @click="listTweets" size="sm">Retrieve
                            Tweets</b-button>
                        <ConfirmShilling class="mt-2" :value="tweets.length"></ConfirmShilling>
                        <EmergencyModal class="mt-2"> </EmergencyModal>

                    </b-col>
                    <b-col cols="4">
                        <b-row>
                            <b-col cols="6">


                                <b-form-group label="Automate Shilling" v-slot="{ ariaDescribedby }">
                                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"
                                        :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"
                                        :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-button class="mt-2" variant="nature3" @click="shill" size="sm">GO</b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col lg="4" md="4" sm="4" xs="6">
                        <b-row>
                            <b-col cols="6">

                                <b-form-group label="Account Overwatch" v-slot="{ ariaDescribedby2 }">
                                    <b-form-radio v-model="selected2" :aria-describedby2="ariaDescribedby2"
                                        name="some-radios2" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected2" :aria-describedby2="ariaDescribedby2"
                                        name="some-radios2" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-button class="mt-2" variant="nature3" @click="detectMention" size="sm">GO</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" md="4" sm="4" xs="6">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="AI Tweet Processing" v-slot="{ ariaDescribedby3 }">
                                    <b-form-radio v-model="selected3" :aria-describedby3="ariaDescribedby3"
                                        name="some-radios3" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected3" :aria-describedby3="ariaDescribedby3"
                                        name="some-radios3" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-button class="mt-2" variant="nature3" @click="retweetFavorite" size="sm">GO</b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col lg="4" md="4" sm="4" xs="6">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="AI Automation" v-slot="{ ariaDescribedby4 }">
                                    <b-form-radio v-model="selected4" :aria-describedby4="ariaDescribedby4"
                                        name="some-radios4" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected4" :aria-describedby4="ariaDescribedby4"
                                        name="some-radios4" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-button class="mt-2" variant="nature3" @click="getPrivateMessage" size="sm">GO</b-button>

                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col lg="4" md="4" sm="4" xs="6">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="Shill Tweet Analysis" v-slot="{ ariaDescribedby5 }">
                                    <b-form-radio v-model="selected5" :aria-describedby5="ariaDescribedby5"
                                        name="some-radios5" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected5" :aria-describedby5="ariaDescribedby5"
                                        name="some-radios5" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-button class="mt-2" variant="nature3" @click="aiPredict" size="sm">GO</b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>

                <b-row class="mt-3">
                    <b-col lg="4" md="4" sm="4" xs="6">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="Transfer to Friends" v-slot="{ ariaDescribedby6 }">
                                    <b-form-radio v-model="selected6" :aria-describedby6="ariaDescribedby6"
                                        name="some-radios6" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected6" :aria-describedby6="ariaDescribedby6"
                                        name="some-radios6" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-button size="sm" class="mt-2" variant="nature3" @click="transferWhitelist">GO</b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col lg="4" md="4" sm="4" xs="6">

                        <div class="form-group">
                            <label for="attributes"><b>Tweet text</b></label>
                            <div v-for="(st, index) in twitt_string" :key="index">{{ index }} {{ st }} </div>
                        </div>


                    </b-col>

                    <b-col lg="4" md="4" sm="4" xs="6">

                        <div class="form-group">
                            <label for="media"><b>Media</b></label>
                            <div v-for="(st, index) in media_ids" :key="index">{{ index }} {{ st }} </div>

                        </div>

                    </b-col>



                </b-row>

            </div>

        </b-collapse>


        <b-collapse id="collapse" class="mt-2" v-model="retrieveVisible">
            <b-button class="m-2" variant="danger" @click="deleteTweets" size="sm">Delete Tweets</b-button>
            <b-row>
                <b-col lg="4" md="4" sm="4" xs="6" v-for="tweet in tweets" :key="tweet.id">
                    <b-card class="mb-2 mt-3 twt">
                        <b-card-text>
                            <p>{{ tweet.id }}</p>
                            <p>{{ tweet.text }}</p>
                            <p>{{ tweet.user.name }}</p>
                            <p>{{ tweet.created_at }}</p>
                            <p>Has shilled: {{ tweet.shill }}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-collapse>

        <b-collapse id="collapse-5" class="mt-2" v-model="shillVisible">
            <b-button class="mt-2" variant="danger" @click="clearShillTweets" size="sm">Clear Tweet
                list</b-button>
            <b-row>
                <b-col lg="4" md="4" sm="4" xs="6" v-for="tweet in shillTweets" :key="tweet.id">
                    <b-card class="mb-2 mt-3 twt">
                        <b-card-text>
                            <p>ID: {{ tweet.id }}</p>
                            <p>{{ tweet.text }}</p>
                            <p>{{ tweet.username }}</p>
                            <p class="mt-2">User:</p>
                            <p>bio: {{ tweet.user_description }}</p>
                            <p>ID: {{ tweet.user_id }}</p>
                            <p>Username: {{ tweet.user_name }}</p>
                            <p>Tansfered: {{ tweet.transfered }}</p>
                        </b-card-text>
                    </b-card>

                </b-col>
            </b-row>

        </b-collapse>

        <b-collapse id="collapse-7" class="mt-2" v-model="autoVisible">

            <b-row>
                <b-col lg="4" md="4" sm="4" xs="6" v-for="tweet in autoTweet" :key="tweet._id">
                    <b-card class="mb-2 mt-3 twt">
                        <b-row>
                            <b-col>


                                <b-card-text>


                                    <div class="add" v-for="(attribute, index) in tweet.twitt_string" :key="index">
                                        <p>{{ attribute }}</p>
                                    </div>


                                    <div class="add" v-for="tm in tweet.time" :key="tm._id">
                                        {{ tm.date }} {{ tm.time }}


                                    </div>
                                    <div class="mt-3" v-for="(md, index3) in tweet.media_ids" :key="index3">
                                        {{ index3 }} {{ md }}

                                    </div>
                                    <div class="mt-3">
                                        Active: {{ tweet.active }}

                                    </div>
                                </b-card-text>
                            </b-col>
                            <b-col cols="ml-auto">
                                <b-button class="mt-2" variant="danger" @click="deleteAutoTweet(tweet._id)"
                                    size="sm"><b-icon icon="trash"></b-icon></b-button>
                                <EditAutoTweet :id="tweet._id" />
                                <b-button class="mt-2" variant="nature4" size="sm"
                                    @click="toggleEvent(tweet._id, !tweet.active)">
                                    <b-icon v-if="tweet.active" icon="toggle-on"></b-icon>
                                    <b-icon v-else icon="toggle-off"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                </b-col>
            </b-row>

        </b-collapse>

    </div>
</template>
  
<script>

import TwitterService from '../services/twitter.service';
import DeleteModal from '../components/DeleteModal.vue';
import Whitelist from '../components/Whitelist.vue';
import EmergencyModal from '../components/EmergencyModal.vue';
import ConfirmShilling from '../components/ConfirmShilling.vue';
import SetTwitQuery from '../components/SetTwitQuery.vue';
import EditQueryModal from '../components/EditQueryModal.vue';
import TwitterCredentialModal from '../components/TwitterCredentialModal.vue';
import AutoTweet from '../components/AutoTweet.vue';
import EditAutoTweet from '../components/EditAutoTweet.vue';

import EventBus from '../common/EventBus';

export default {
    name: 'Profile',
    data() {
        return {
            query: '',
            delay: 0,
            twitt_string: [],
            media_ids: [],
            selected: false,
            selected2: false,
            selected3: false,
            selected4: false,
            selected5: false,
            selected6: false,
            followers: [],
            whitelist: [],
            settingsVisible: false,
            retrieveVisible: false,
            aiVisible: false,
            shillVisible: false,
            autoTweet: [],
            autoVisible: false,
        };
    },
    components: {
        DeleteModal,
        Whitelist,
        EmergencyModal,
        ConfirmShilling,
        SetTwitQuery,
        EditQueryModal,
        TwitterCredentialModal,
        AutoTweet,
        EditAutoTweet,
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        queryFilled() {
            return this.media_ids.length > 0;
        },
        tweets() {
            return this.$store.getters['twitter/getTweets'];
        },
        userTweets() {
            return this.$store.getters['twitter/getUserTweets'];
        },
        aiTweets() {
            return this.$store.getters['twitter/getAITweets'];
        },
        shillTweets() {
            return this.$store.getters['twitter/getShillTweets'];
        },
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        this.loadTwitt();
        this.loadFollowers();
        this.loadWhitelist();
        this.loadAutoTweet();
    },
    created() {
        this.interval = setInterval(async () => {
            await this.$store.dispatch('twitter/listTweets')
            await this.$store.dispatch('twitter/getAllTweets')
            await this.$store.dispatch('twitter/getUserTweets')
            await this.$store.dispatch('twitter/getAITweets')
            await this.$store.dispatch('twitter/getShillTweets')

        }, 60000)
        this.$store.dispatch('twitter/listTweets')
        this.$store.dispatch('twitter/getAllTweets')
        this.$store.dispatch('twitter/getUserTweets')
        this.$store.dispatch('twitter/getAITweets')
        this.$store.dispatch('twitter/getShillTweets')
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        loadTwitt: function () {
            TwitterService.getTwitt().then(response => {
                this.query = response.query;
                this.delay = response.delay;
                this.twitt_string = response.twitt_string;
                this.media_ids = response.media_ids;
                this.selected = response.shill;
                this.selected2 = response.overwatch;
                this.selected3 = response.ai_processing;
                this.selected4 = response.auto_reply;
                this.selected5 = response.nlp;
                this.selected6 = response.transfer;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout')
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        loadFollowers: function () {
            TwitterService.getFollowers().then(response => {
                this.followers = response;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout')
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        loadWhitelist: function () {
            TwitterService.getWhitelistFriends().then(response => {
                this.whitelist = response;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout')
                }

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        loadAutoTweet: function () {
            TwitterService.getAutoTweet().then(response => {
                this.autoTweet = response || [];

            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        listTweets: function () {
            this.$store.dispatch('twitter/listTweets')
        },
        deleteTweets: function () {
            this.$store.dispatch('twitter/deleteAllTweets')
        },
        manualShill: function () {
            this.$store.dispatch('twitter/manualShill')
        },
        shill: function () {
            const obj = {
                state: this.selected
            }
            this.$store.dispatch('twitter/shill', obj)
        },
        detectMention: function () {
            const obj = {
                state: this.selected2
            }
            this.$store.dispatch('twitter/detectMention', obj)
        },
        retweetFavorite: function () {

            const obj = {
                state: this.selected3
            }
            this.$store.dispatch('twitter/retweetFavorite', obj)
        },
        getPrivateMessage: function () {
            const obj = {
                state: this.selected4
            }
            this.$store.dispatch('twitter/getTwitterPrivateMessages', obj)
        },
        aiPredict: function () {
            const obj = {
                state: this.selected5
            }
            this.$store.dispatch('twitter/aiPredict', obj)
        },
        whitelistAddress: function (data) {
            console.log(data)
            this.$store.dispatch('twitter/whitelistFriend', data)
        },
        transferWhitelist: function () {
            const obj = {
                state: this.selected6
            }
            this.$store.dispatch('twitter/transferTweet', obj)
        },
        resetwhitelist: function () {
            this.$store.dispatch('twitter/resetWhitelist')
        },
        clearShillTweets: function () {
            this.$store.dispatch('twitter/clearShillTweets')
        },
        clearAITweets: function () {
            this.$store.dispatch('twitter/clearAITweets')
        },
        toggleEvent: function (id, active) {
            const data = {
                id: id,
                state: active
            }
            this.$store.dispatch('twitter/startAutoTweet', data)
        },
        deleteAutoTweet: function (id) {
            const data = {
                id: id
            }
            this.$store.dispatch('twitter/deleteAutoTweet', data)
        },
    }
};
</script>
<style scoped>
.info {
    margin-top: 40px;
}

.top {
    margin-top: 20px;
}

.twt {
    font-size: 10px;
}
</style>