<template>
  <div>
    <div>
     <b-button @click="confirmDelete" size="sm" variant="danger">
     <b-icon icon="trash"></b-icon>
     </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        
      }
    },
    computed: {
    
    },
    methods: {
      confirmDelete() {
      
        this.$bvModal.msgBoxConfirm('Delete Tweet?')
          .then(value => {
            if (value) {
              this.$store.dispatch('twitter/deleteQuery', this.id)
    
            }
          })
          .catch(err => {
            console.log(err);
          })
      },
    }
  }
</script>