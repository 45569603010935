<template>
  <div class="main">

    <b-navbar toggleable="md" type="light" :sticky="true">
      <b-navbar-brand class="mx-2" href="/">
        <!--<img src="logo.png" width="50" height="20" class="d-inline-block"> -->
        Archetype
      </b-navbar-brand>


      <b-navbar-nav>

        <b-nav-item>
          <ChatModal></ChatModal>
        </b-nav-item>
      </b-navbar-nav>


      <b-navbar-nav class="ml-auto">
        <b-button @click="connectWallet" size="sm" variant="nature1">
        <b-icon icon="bag-fill"></b-icon>
        {{ wallet }}

      </b-button>
      <CreateNote></CreateNote>
    </b-navbar-nav>

    </b-navbar>

    <div class="container text-dark">


      <div class="mt-3 mb-3">
        <div>
          <b-button class="m-1" variant="nature1" size="sm" v-bind:class="{ active: currentFilter === 'all' }"
            v-on:click="setFilter('all')">all</b-button>
          <b-button class="m-1" variant="nature1" size="sm" v-for="(tag, index) in tags" :key="index"
            v-bind:class="{ active: currentFilter === tag }" v-on:click="setFilter(tag)">{{ tag
            }}</b-button>
        </div>
      </div>
      <div>
        <b-card-group columns>
          <b-card overlay class="overflow-hidden m-2" bg-variant="dark" v-for="note in filteredNotes" :key="note.id"
          text-variant="white" :img-src="note.imageUrl">

          <b-row>
            <b-col>
       
            <div>{{ note.title }} </div>
       
          </b-col>
          <b-col class="ml-auto">
            <DeleteNote v-if="currentUser" class="mt-2" :path="'note/deleteNote'" :id="note.id" />
          </b-col>
          </b-row>

          <b-form-textarea class="mt-2" size="sm" v-model="note.content" :rows="3" :max-rows="3"
            @input="editNote(note.content, note.id)"></b-form-textarea>
                   
              

            <ToDo :note="note" />

          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>

import DeleteNote from '@/components/DeleteNote.vue';
import ToDo from '../components/ToDo.vue'
import ChatModal from '@/components/ChatModal.vue';
import CreateNote from '@/components/CreateNote.vue';

export default {
  name: 'Notes',
  data() {
    return {
      currentFilter: "all",
      loading: false,
      content: '',
    };
  },
  components: {
    DeleteNote,
    ToDo,
    ChatModal,
    CreateNote
  },
  mounted() {



  },
  created() {

  this.$store.dispatch('note/loadNote')
  this.$store.dispatch('note/loadTag')
  },
  computed: {
    currentUser() {
            return this.$store.state.auth.user;
        },
    loggedIn() {
      return this.$store.state.nft.loggedIn;
    },
    walletAddress() {
      return this.$store.getters['nft/loadedWallet'];
    },
    wallet() {
      if (this.walletAddress === '') {
        return 'Wallet'
      } else {
        return String(this.walletAddress).substring(0, 4) + "..." + String(this.walletAddress).substring(40);
      }
    },
    notes() {
      return this.$store.getters['note/loadedNotes'];
    },
    tags() {
      return this.$store.getters['note/loadedTags'];
    },
    filteredNotes: function () {
      const notes = this.notes
      if (this.currentFilter === "all") {
        return notes
      }
      else {
        return this.notes.filter((note) => {
          let tag = []
          for (let obj in note.tag) {
            tag.push(note.tag[obj])
          }
          return tag.some(item => item === this.currentFilter)
        })
      }
    }
  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch('nft/connectWallet');
    },
    setFilter: function (filter) {
      this.currentFilter = filter
    },
    deleteNote: function (id) {
      this.loading = true;
      this.$store.dispatch('note/deleteNote', id)
      this.loading = false;
    },
    editNote: function (content, id) {
      this.loading = true;
      const noteData = {
        id: id,
        content: content
      }
      this.$store.dispatch('note/editNote', noteData)
      this.loading = false;
    }
  }
};
</script>
<style scoped>
.card {
  display: inline-block;
}

.tiny {
  font-size: 8pt;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>