<template>
    <div>
        <b-button variant="primary" v-b-modal.modal7-prevent-closing size="sm"><b-icon icon="plus"></b-icon>
            Note</b-button>

        <b-modal size="xl" id="modal7-prevent-closing" ref="modal7" title="Query tweets" @show="resetModal7"
            @hidden="resetModal7" @ok="handleOk7">

            <b-row>
                <b-col lg="6" md="6" sm="6" xs="6">


                    <div class="form-group">
                        <label for="title">Title</label>
                        <input v-model="note.title" v-validate="'required'" type="text" class="form-control" name="title" />
                    </div>

                    <div class="form-group">
                        <label for="content">Content</label>
                        <b-form-textarea id="content" v-model="note.content" placeholder="..." rows="3"
                            max-rows="6"></b-form-textarea>
                    </div>

                    <div>
                        <label for="isPublic">isPublic?</label>
                        <input v-model="note.isPublic" type="checkbox" id="isPublic" name="isPublic" checked>
                    </div>
                    <div>
                        <label for="tag">Tag</label>
                        <b-form-tags input-id="tags-basic" v-model="note.tag"></b-form-tags>
                    </div>



                </b-col>
                <b-col lg="6" md="6" sm="6" xs="6">

                    <div class="form-group mt-2">
                        <b-button variant="primary" @click="onPickFile">Upload image </b-button>
                        <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked">

                        <img :src="imageUrl" height="200">

                        <div class="add">{{ imgUrl.ipfsHash }}</div>
                    </div>
                </b-col>
            </b-row>

        </b-modal>
    </div>
</template>
  
<script>



export default {
    data() {
        return {
            loading: false,
            message: '',
            note: {
                title: '',
                content: '',
                isPublic: false,
                tag: [],
            },
            image: null,
            imageUrl: ''
        }
    },
    components: {
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        imgUrl() {
            return this.$store.getters['note/loadedImgUrl'];
        }

    },
    mounted() {
       
    },
    methods: {


        resetModal7() {

        },
        handleOk7(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit7()
        },
        handleSubmit7() {
            // Exit when the form isn't valid
            this.loading = true;


            if (!this.note.title && !this.note.content) {
                return
            }
            const noteData = {
                title: this.note.title,
                content: this.note.content,
                isPublic: this.note.isPublic,
                imageUrl: "https://ipfs.io/ipfs/" + this.imgUrl.ipfsHash,
                tag: this.note.tag,
            }

            this.$store.dispatch('note/createNote', noteData)
 



            this.$nextTick(() => {
                this.$bvModal.hide('modal7-prevent-closing')
            })
        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files
            let filename = files[0].name
            if (filename.lastIndexOf('.') <= 0) {
                return alert('Please add a valid file!')
            }
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
            this.$store.dispatch('note/uploadImg', files[0])

        },
        
    }
}
</script>
<style scoped>
.add {
    font-size: 10pt;
}

.form-group {
    font-size: 10pt;
}
</style>