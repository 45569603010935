<template>
    <div>
        <!-- Button that triggers the modal -->
        <div @click="showModal = true" class="text-dark">
            Chat
        </div>

        <!-- Modal -->
        <b-modal v-model="showModal" title="Chat" size="lg" centered>
            <div class="chat-container h-100 d-flex flex-column">
                <!-- Chat messages go here -->
                <div class="message d-flex" v-for="(prediction, index) in predictions" :key="index">
                    <div class="content">
                        <div class="text">{{ prediction.prompt }}</div>
                    </div>
                    <div class="content">
                        <div class="username text-primary font-weight-bold">{{ prediction.author }}</div>
                        <div class="text">{{ prediction.content }}</div>
                        <div class="timestamp text-secondary font-weight-light">{{ prediction.date | date }}</div>
                    </div>
                </div>
            </div>
            <div class="input-group mt-3">
                <form name="form" @submit.prevent="createNode">
                    <div class="form-group">
                        <label for="title">Enter a prompt</label>

                        <b-form-textarea id="prompt" v-model="prompt" placeholder="Prompt" rows="3"
                            max-rows="6"></b-form-textarea>
                    </div>


                    <div class="form-group mt-2">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Create</span>
                        </button>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>
  
<script>


export default {
    data() {
        return {
            showModal: false,
            loading: false,
            prompt: '',
        }
    },
    computed: {
        // populate the predictions array with the predictions from the store
        predictions() {
            return this.$store.getters['ai/loadedPredictions'];
        },

    },
    mounted() {
    },
    methods: {
        createNode: function () {
            this.loading = true;


            if (this.prompt) {
                const promptData = {
                    prompt: this.prompt,
                }

                this.$store.dispatch('ai/queryAI', promptData)
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.chat-container {
    overflow-y: auto;
    flex: 1;
}
</style>