import api from "./api2";

class NoteService {
  create(note) {
    return api
      .post("/note", {
        title: note.title,
        content: note.content,
        isPublic: note.isPublic,
        imageUrl: note.imageUrl,
        tag: note.tag
      })
      .then(response => {
        
        return response.data;
      });
  }
  upload(matter) {
    let formData = new FormData();

    formData.append("file", matter);
    
    return api
      .post("/aws/upload", formData, 
      {
        headers: {
          "Content-Type": "multipart/form-data" }

      })
      .then(response => {
        return response.data;
      });
  }
  loadNote() {
    return api.get("/note")
      .then(response => {
        
        return response.data;
      })
  }
  loadTag() {
    return api.get("/tag")
      .then(response => {
        
        return response.data;
      })
  }
  edit(note) {
    return api
      .post("/note/edit/" + note.id, {
        content: note.content,
      })
      .then(response => {
        
        return response.data;
      });
  }
  delete(id) {
    return api
      .delete("/note/" + id, {})
      .then(response => {
        
        return response.data;
      });
  }
  addTodo(todo) {
    return api
      .post("/note/todo/" + todo.id, {
        content: todo.content,
        done: todo.done
      })
      .then(response => {
        
        return response.data;
      });
  }
  validate(todo) {
    return api
      .post("/note/todo/done/" + todo.id, {
        noteId: todo.noteId,
      })
      .then(response => {
        
        return response.data;
      });
  }

}

export default new NoteService();