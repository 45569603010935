import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VeeValidate from 'vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMasonry from 'vue-masonry-css'

import './custom.scss'

import DateFilter from './filters/date.js'

import setupInterceptors from './services/setupInterceptors'
import setupInterceptors2 from './services/setupInterceptors2'

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt)

Vue.config.productionTip = false


Vue.use(Vuex);

setupInterceptors(store);
setupInterceptors2(store);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueMasonry);

Vue.use(VeeValidate)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.filter('date', DateFilter)


new Vue({
  router,
  store,
  render: h => h(App),
  created () {
  this.$store.dispatch('media/loadMedia')
  this.$store.dispatch('media/loadFootprint')
  this.$store.dispatch('article/loadArticle')
  this.$store.dispatch('nft/getCurrentWallet')
  this.$store.dispatch('nft/checkHash')
  }
}).$mount('#app')
