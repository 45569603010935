import AiService from '../services/ai.service';

import EventBus from "../common/EventBus";

export const ai = {
  namespaced: true,
  state: {
    predictions: [],
    codex: [],
    codeCompletion: [],
    currentProject: {
      "id": "example",
      "css": "body {}",
      "javascript": "(function() {})()",
      "html": "<div>Hello World</div>",
      "prompt": "Hello World"
    },
    message: ''

  },
  actions: {
    queryAI({ commit }, payload) {
      return AiService.create(payload).then(
        data => {
          commit('saveQuery', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    queryCodexAI({ commit }, payload) {
      return AiService.complete(payload).then(
        data => {
          commit('saveCodexQuery', data);
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', error)
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    queryCompleteCode({ commit }, payload) {
      return AiService.code(payload).then(
        data => {
          commit('saveCodeQuery', data);
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', error)
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    editorChanged({ commit }, payload) {
      let currentProject = {}
      const syntax = payload.syntax
      const content = payload.content

      currentProject[syntax] = content


      commit('editorChanged', {
        syntax: syntax,
        currentProject: currentProject

      })

    },
  },
  mutations: {
    saveQuery(state, payload) {
      state.predictions.push(payload);
    },
    saveCodeQuery(state, payload) {
      state.codeCompletion.push(payload);
    },
    saveCodexQuery(state, payload) {
      state.codex.push(payload);

      const content = JSON.parse(payload.content)

      // if content is empty, then we have an error

      state.currentProject.prompt = payload.prompt
      state.currentProject.html = content.html
      state.currentProject.css = content.css
      state.currentProject.javascript = content.javascript
    },
    editorChanged(state, payload) {
      state.currentProject[payload.syntax] = payload.currentProject[payload.syntax]
    },
    setMessage(state, payload) {
      state.message = payload;
    }
  },
  getters: {
    currentProject(state) {
      return state.currentProject
    },
    loadedPredictions(state) {

      const sortedArray = [...state.predictions]
      return sortedArray.sort((predA, predB) => {
        const da = new Date(predA.date)
        const db = new Date(predB.date)
        if (da === db) { return 0 }

        return da < db ? 1 : -1
      })
    },
    loadedCodexPredictions(state) {

      const sortedArray = [...state.codex]
      return sortedArray.sort((predA, predB) => {
        const da = new Date(predA.date)
        const db = new Date(predB.date)
        if (da === db) { return 0 }

        return da < db ? 1 : -1
      })
    },
    loadedCodeCompletion(state) {

      const sortedArray = [...state.codeCompletion]
      return sortedArray.sort((predA, predB) => {
        const da = new Date(predA.date)
        const db = new Date(predB.date)
        if (da === db) { return 0 }

        return da < db ? 1 : -1
      })
    },
    loadedMessage(state) {
      return state.message
    }
  }
}