<template>
    <div>
        <b-button variant="light" class="m-2" v-b-modal.modal2-prevent-closing size="sm"><b-icon icon="person-plus-fill" /></b-button>

        <b-modal size="xl" id="modal2-prevent-closing" ref="modal2" title="Create a secure account" @show="resetModal2"
            @hidden="resetModal2" @ok="handleOk2">
            <form ref="form" @submit.stop.prevent="handleSubmit2">
                <!-- Horizontal b-card media form post -->
                <b-card no-body class="overflow-hidden">
                    <b-row no-gutters>
                        <b-col lg="6" md="6" sm="6" xs="12">

                        </b-col>
                        <b-col lg="6" md="6" sm="6" xs="12">
                            <b-card-body title="User Data">

                                <div class="form-group">
                                    <p>Username</p>
                                    <input v-model="user.username" type="text" class="form-control" name="name" />
                                </div>

                                <div class="form-group">
                                    <p>Password</p>
                                    <input v-model="user.password" type="password" class="form-control" name="name" />
                                </div>


                            </b-card-body>
                        </b-col>
                    </b-row>
                </b-card>

            </form>
        </b-modal>
    </div>
</template>
  
<script>


export default {
    data() {
        return {
            loading: false,
            message: '',
            user: {
                username: '',
                password: ''
            },
        }
    },
    computed: {
     
    },
    methods: {
       

        resetModal2() {
            this.user.username = ''
            this.user.password = ''
        },
        handleOk2(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit2()
        },
        handleSubmit2() {
            // Exit when the form isn't valid
            this.loading = true
            if (!this.user.username && !this.user.password) {
                this.loading = false;
                this.message = 'Please fill all fields';
                return
            }

           

            const userData = {
                username: this.user.username,
                password: this.user.password
            }

            this.$emit('registerUser', userData)

            this.$nextTick(() => {
                this.$bvModal.hide('modal2-prevent-closing')
            })
        }
    }
}
</script>
<style scoped>

.add {
    font-size: 10pt;
}
</style>