import AuthService from '../services/auth.service';
require('dotenv').config();

//import EventBus from '../common/EventBus';



const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async smartContractRegister({ commit, rootState }) {
      const payload = {
        address: rootState.nft.walletAddress,
        hash: rootState.nft.hash
      }
      return AuthService.smartContractRegister(payload).then(
        data => {
          commit('registerSuccess', data);
          commit('nft/setMessage', "Registration successful", { root: true })
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          commit('nft/setMessage', "Error registering", { root: true })
          return Promise.reject(error);
        }
      );
    },

    async smartContractLogin({ commit, rootState }) {
      const payload = {
        address: rootState.nft.walletAddress,
        hash: rootState.nft.hash
      }
      return AuthService.smartContractLogin(payload).then(
        data => {
          commit('loginSuccess', data);
          commit('nft/setMessage', "Login successful", { root: true })
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          commit('nft/setMessage', "Error logging in", { root: true })
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      commit('logout');
      commit('nft/setMessage', "Logged out", { root: true })
    },
    
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },

    editProfile({ commit }, payload) {
      return AuthService.editProfile(payload).then(
        data => {
          commit('editProfile', data);
          commit('nft/setMessage', "Profile updated", { root: true })
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          commit('nft/setMessage', "Error updating Profile", { root: true })
          return Promise.reject(error);
        }
      );
    },
    saveCredentials({ commit }, payload) {
      return AuthService.saveCredentials(payload).then(
        data => {
          commit('nft/setMessage', "Credentials saved", { root: true })
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          commit('nft/setMessage', "Error saving Credentials", { root: true })
          return Promise.reject(error);
        }
      );
    },
    deleteUser({ commit }) {
      return AuthService.deleteUser().then(
        data => {
          commit('logout');
          commit('nft/setMessage', data.message, { root: true })
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          commit('nft/setMessage', "Error deleting User", { root: true })
          return Promise.reject(error);
        }
      );
    },
    uploadProfileImg({ commit, dispatch }, payload) {
      return AuthService.upload(payload).then(
        data => {
          commit('uploadProfileImg', data);
          commit('nft/setMessage', "Profile image updated", { root: true })
          return Promise.resolve(data);
        },
        error => {
          if (error.response && error.response.status === 403) {
            dispatch('logout')
          }
          commit('nft/setMessage', "Error updating Profile image", { root: true })
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    uploadProfileImg(state, payload) {
      state.user['imageUrl'] = payload.imageUrl;
    },
    editProfile(state, user) {
      state.user.username = user.username;
      state.user.email = user.email;
      state.user.imageUrl = user.imageUrl;
    },
  },
  getters: {
   
  }
};