<template>
  <div class="main">
    <b-navbar toggleable="md" type="light" :sticky="true">
      <b-navbar-brand class="mx-2" href="/">
        <!--<img src="logo.png" width="50" height="20" class="d-inline-block"> -->
        Archetype
      </b-navbar-brand>


      <b-navbar-nav>

        <b-nav-item>
          <ChatModal></ChatModal>
        </b-nav-item>
      </b-navbar-nav>


      <b-navbar-nav class="ml-auto">
        <b-button @click="connectWallet" size="sm" variant="nature1">
          <b-icon icon="bag-fill"></b-icon>
          {{ wallet }}

        </b-button>

      </b-navbar-nav>


    </b-navbar>

    <div class="container text-dark mt-3">
      <b-row>
        <b-col md="6" xs="12">
          <h1>Archetype</h1>

          <h3>by Philmo</h3>
          <div class="headline">AI Based Security Systems | Augmented Collective Intelligence | Social Engineering
          </div>

          <hr />


          <div class="headline">Towards equity, sustainable social ecology and global security through computational
            systems.</div>
          <b-button-group>
            <b-button :to="'/hub'" variant="nature1" class="mt-3 mr-2"><b-icon icon="cpu" /> Hub</b-button>
          </b-button-group>
          <b-button-group v-if="!currentUser">
            <b-button @click="twitterLogin" variant="nature2" class="mt-3">Login</b-button>
            <b-button @click="twitterRegister" variant="nature3" class="mt-3">Register</b-button>
          </b-button-group>
          <b-button-group v-else>
            <b-button :to="'/twitter'" variant="nature2" class="mt-3"><b-icon icon="twitter" /> Twitter I/O</b-button>
            <b-button :to="'/notes'" variant="nature4" class="mt-3"><b-icon icon="pencil" /> Notes</b-button>
          </b-button-group>

          <hr />
        </b-col>

        <b-col md="6" xs="12">
          <div class="spacer"></div>
          <b-img src="/polar-bear.jpg" fluid></b-img>
          <div class="liste mt-3">
            <p>ƒ() Predictive Analysis</p>
            <p>ƒ() Pattern recognition security</p>
            <p>ƒ() Assistive productivity tools</p>
          </div>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>

import ChatModal from '@/components/ChatModal.vue';

export default {
  name: 'Home',
  components: {
    ChatModal
  },
  data() {
    return {
      content: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.nft.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    walletAddress() {
      return this.$store.getters['nft/loadedWallet'];
    },
    wallet() {
      if (this.walletAddress === '') {
        return 'Wallet'
      } else {
        return String(this.walletAddress).substring(0, 4) + "..." + String(this.walletAddress).substring(40);
      }
    },
  },
  mounted() {

  },
  methods: {
    connectWallet: function () {
      this.$store.dispatch('nft/connectWallet');
    },
    twitterLogin: function () {
      this.$store.dispatch('auth/smartContractLogin');
    },
    twitterRegister: function () {
      this.$store.dispatch('auth/smartContractRegister');
    }
  }
};
</script>
<style scoped>
.spacer {
  margin-top: 40px;
}

.headline {
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.liste {
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
</style>