<template>
  <div id="app" class="text-light">

    <div class="sections-menu text-light">
      <span class="menu-point" v-bind:class="{ active: activeSection == index }" v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets" v-bind:key="index">
      </span>
    </div>

    <section class="fullpage home">
      <b-avatar variant="info" src="/philmo.jpeg" class="m-3" size="6rem"></b-avatar>
      <h1>Philmo</h1>
      <p>Web dev | Generative Art | Media </p>
    </section>
    <section class="fullpage mediacontent">

      <masonry :cols="{ default: 5, 1000: 5, 700: 4, 400: 3 }" :gutter="0">

        <b-img src="generative/t4t.jpg" fluid></b-img>
        <b-img src="generative/diffusion1.JPG" fluid></b-img>
        <b-img src="generative/latenttones1.jpg" fluid></b-img>
        <b-img src="generative/diffusion2.jpg" fluid></b-img>
        <b-img src="generative/ethchords.JPG" fluid></b-img>
        <b-img src="generative/diffusion4.JPG" fluid></b-img>
        <b-img src="generative/diffusion9.jpg" fluid></b-img>
        <b-img src="generative/t4t1.jpg" fluid></b-img>
        <b-img src="generative/nebula3.JPG" fluid></b-img>
        <b-img src="generative/untitled34.jpg" fluid></b-img>
        <b-img src="generative/diffusion5.jpg" fluid></b-img>
        <b-img src="generative/liquid1.JPG" fluid></b-img>

        <b-img src="generative/latenttones2.jpg" fluid></b-img>
        <b-img src="generative/diffusion10.JPG" fluid></b-img>
        <b-img src="generative/diffusion6.jpg" fluid></b-img>
        <b-img src="generative/diffusion11.jpg" fluid></b-img>

        <b-img src="generative/lightwave.jpg" fluid></b-img>
        <b-img src="generative/diffusion32.jpg" fluid></b-img>
        <b-img src="generative/silkflow_12.JPG" fluid></b-img>

        <b-img src="generative/diffusion26.jpg" fluid></b-img>
        <b-img src="generative/differ2.GIF" fluid></b-img>
        <b-img src="generative/feel3.JPG" fluid></b-img>
        <b-img src="generative/diffusion17.jpg" fluid></b-img>




      </masonry>
    </section>


    <section class="fullpage home3">
      <div class="container text-dark">
        <h4>Article - lts</h4>



        <b-row class="mt-3 mb-3" v-if="articleDisplay">

          <b-col md="6" sm="6">
            <h4>{{ article[0].title }}</h4>
            <div>{{ article[0].date | date }}</div>
            <div class="mt-3 title"><b>{{ article[0].section[0].title }}</b></div>
            <div class="content mb-3" v-html="article[0].section[0].content"></div>
            <b-button variant="dark text-light" :href="'https://ssr.philmo.ch/article/' + article[0].id">Read
              more</b-button>
          </b-col>
          <b-col md="6" sm="6">
            <b-img v-if="article[0].section[0].imageUrl" :src="article[0].section[0].imageUrl" fluid rounded></b-img>
          </b-col>


        </b-row>



      </div>
    </section>

    <section class="fullpage home2">

      <b-row class="container">
        <b-col lg="6" md="6" sm="12" xs="12">


          <div class="mb-3">
            <p>
              <a href="https://twitter.com/philmo_mu?s=20">
                <b-icon icon="twitter" />
                Twitter
              </a>
            </p>
            <p>
              <a href="https://www.instagram.com/philmo_mu/">
                <b-icon icon="instagram" />
                Instagram
              </a>
            </p>
            <p>
              <a href="https://medium.com/@pmosi76">
                <b-icon icon="medium" />
                Medium
              </a>
            </p>
            <p>
              <a href="https://ch.linkedin.com/in/phil-mo-1630bb13a">
                <b-icon icon="linkedin" />
                Linkedin
              </a>
            </p>

          </div>

          <div class="mt-3">
            <p>
              <a href="https://ssr.philmo.ch">
                <b-icon icon="grid1x2" />
                Portfolio
              </a>
            </p>


          </div>

          <div class="mt-3">
            <p>
              <a href="https://ecosys.app">
                <b-icon icon="globe" />
                Ecosys
              </a>
            </p>
            <p>
              <a href="https://wallet.ecosys.app">
                <b-icon icon="wallet2" />
                Wallet Ecosys
              </a>
            </p>

          </div>

        </b-col>

        <b-col lg="6" md="6" sm="6" xs="12">

          <b-row>


            <b-col cols="6" class="mt-3">

              

                <b-img src="/genxyz.jpeg" height="50" center rounded></b-img>
              
              <div class="text-light text-center">
                <div class="title"><a href="https://generative.xyz/profile/bc1pk5k2z3ytxs8pcr34rf5ctdyl8um03yag8e8v423fdfcmc2z5zkfqh8kel9">Generative</a></div>
              </div>
            </b-col>

            <b-col cols="6" class="mt-3">

              
                <b-img src="/objkt.png" height="50" center rounded></b-img>
        
              <div class="text-light text-center">
                <div class="title"><a href="https://objkt.com/profile/philmo/">Objkt</a></div>
              </div>
            </b-col>

            <b-col cols="6" class="mt-3">

              
                <b-img src="/fnd.jpeg" height="50" center rounded></b-img>
             
              <div class="text-light text-center">
                <div class="title"><a href="https://foundation.app/@philmo">Foundation</a></div>
              </div>
            </b-col>

            <b-col cols="6" class="mt-3">


              
                <b-img src="/fxhash.png" height="50" center rounded></b-img>
              
              <div class="text-light text-center">
                <div class="title"><a href="https://www.fxhash.xyz/u/Philmo">Fxhash</a></div>

              </div>

            </b-col>



          </b-row>



        </b-col>
      </b-row>

      <b-row>
        <b-col class="mt-3 text-dark">
          <b-button class="trigger" @click="connectWallet" size="sm" variant="light">
            <b-icon icon="wallet"></b-icon>
            {{ wallet }}

          </b-button>
          <b-button-group v-if="!loggedIn">

            <RegisterModal class="trigger" @registerUser="registerUser"></RegisterModal>
            <LoginModal class="trigger" @loginUser="loginUser"></LoginModal>

          </b-button-group>

          <b-button-group v-else>
            <b-button variant="light" size="sm" v-on:click="scrollToSection(4)">Access</b-button>
            <b-button class="ml-5" variant="danger" size="sm" @click="logOut">LogOut</b-button>
          </b-button-group>
          
        </b-col>
      </b-row>



    </section>

    <section v-if="loggedIn" class="fullpage home4">
      <router-view></router-view>
    </section>

    <b-alert
      v-model="showMessage"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000;"
      variant="warning"
      dismissible
    >
      {{ message }}
    </b-alert>
  </div>
</template>

<script>

import RegisterModal from '@/components/RegisterModal.vue';
import LoginModal from '@/components/LoginModal.vue';
import EventBus from './common/EventBus.js'

export default {
  components: {
    RegisterModal,
    LoginModal
  },
  data() {
    return {
      inMove: false,
      inMoveDelay: 1200,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
      showMessage : false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.nft.loggedIn;
    },
    medias() {
      return this.$store.getters['media/loadedMedias'];
    },
    footprints() {
      return this.$store.getters['media/loadedFootprint'];
    },
    article() {
      return this.$store.getters['article/loadedArticles'];
    },
    articleDisplay() {
      return this.article && this.article.length > 0;
    },
    walletAddress() {
      return this.$store.getters['nft/loadedWallet'];
    },
    wallet() {
      if (this.walletAddress === '') {
        return 'Wallet'
      } else {
        return String(this.walletAddress).substring(0, 4) + "..." + String(this.walletAddress).substring(40);
      }
    },
    message () {
      return this.$store.getters['nft/getMessage'];
    }
    
  },
  watch: {
    message (value) {
      if (value) {
        this.showMessage = true;
      }
    },
  },
  methods: {

    calculateSectionOffsets() {
      let sections = document.getElementsByTagName('section');
      let length = sections.length;
      for (let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    handleMouseWheel: function (e) {

      if (e.wheelDelta < 30 && !this.inMove && this.activeSection !== 4) {
        this.moveUp();
      } else if (e.wheelDelta > 30 && !this.inMove && this.activeSection !== 4) {
        this.moveDown();
      }

      e.preventDefault();
      return false;
    },
    /**
     * Handle the 'DOMMouseScroll' event for Firefox
     */
    handleMouseWheelDOM: function (e) {

      if (e.detail > 0 && !this.inMove && this.activeSection !== 4) {
        this.moveUp();
      } else if (e.detail < 0 && !this.inMove && this.activeSection !== 4) {
        this.moveDown();
      }

      return false;
    },
    /**
     * Move to the previous section or the last section if you're on the first section
     */
    moveDown() {
      this.inMove = true;
      this.activeSection--;

      if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;

      this.scrollToSection(this.activeSection, true);
    },
    /**
     * Move to the next section or the first section if you're on the last section
     */
    moveUp() {
      this.inMove = true;
      this.activeSection++;

      if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;

      this.scrollToSection(this.activeSection, true);
    },
    /**
     * Scrolls to the passed section id if the section exists and the delay is over
     */
    scrollToSection(id, force = false) {
      if (this.inMove && !force) return false;

      this.activeSection = id;
      this.inMove = true;

      // get section and scroll into view if it exists
      let section = document.getElementsByTagName('section')[id];
      if (section) {
        document.getElementsByTagName('section')[id].scrollIntoView({ behavior: 'smooth' });
      }

      setTimeout(() => {
        this.inMove = false;
      }, this.inMoveDelay);

    },
    /**
     * Handles the 'touchstart' event on mobile devices
     */
    touchStart(e) {
      if (e.target.tagName === "A" || e.target.tagName === "BUTTON"
         || e.target.tagName === "INPUT") {
        return;
      }
      e.preventDefault();

      this.touchStartY = e.touches[0].clientY;
      
      return false;
    },
    /**
     * Handles the 'touchmove' event on mobile devices
     */
    touchMove(e) {
      if (this.inMove) return false;
      e.preventDefault();

      const currentY = e.touches[0].clientY;

      if (this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }

      this.touchStartY = 0;
      return false;
    },
    connectWallet: function () {
      this.$store.dispatch('nft/connectWallet');
    },
    registerUser: function (data) {
      if (!this.walletAddress) {
        return;
      }
      this.$store.dispatch('nft/register', data);
    },

    loginUser: function (data) {
      if (!this.walletAddress) {
        return;
      }
      this.$store.dispatch('nft/login', data);
    },
    logOut: function () {
      this.$store.dispatch('nft/logout');
    },
    logOutAPI: function () {
      this.$store.dispatch('auth/logout');
    },
  },
  mounted() {

    this.calculateSectionOffsets();

    window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
    window.addEventListener('mousewheel', this.handleMouseWheel, {
      passive: false
    }); // Other browsers

    window.addEventListener('touchstart', this.touchStart, {
      passive: false
    }); // mobile devices
    window.addEventListener('touchmove', this.touchMove, {
      passive: false
    });

    EventBus.on("logout", () => {
      this.logOutAPI();
    });



  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
  destroyed() {
    window.removeEventListener('mousewheel', this.handleMouseWheel, {
      passive: false
    }); // Other browsers
    window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
    window.removeEventListener('touchstart', this.touchStart); // mobile devices
    window.removeEventListener('touchmove', this.touchMove); // mobile devices
  }
};
</script>
<style scoped>
.background-container {
  position: absolute;
  min-width: 100%;
  height: 100%;
  z-index: -1;
}

.cover {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  background: radial-gradient(ellipse at bottom, #aba5f5 40%, #284268 100%);
  background-size: 400% 400%;
  z-index: -1;
}

.bckg {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 180px;
  padding-bottom: 80px;
}

.fullpage {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.sections-menu {
  position: fixed;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.sections-menu .menu-point {
  width: 10px;
  height: 10px;
  background-color: #000;
  display: block;
  margin: 1rem 0;
  opacity: .6;
  transition: .4s ease all;
}

.sections-menu .menu-point.active {
  opacity: 1;
  transform: scale(1.5);
}

.sections-menu .menu-point:hover {
  opacity: 1;
  transform: scale(1.2);
}

.home {
  background: url('/DSC_5904.JPG') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home2 {
  background: radial-gradient(ellipse at bottom, #aba5f5 40%, #284268 100%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home3 {
  background-color: #FEFEFE;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding-top: 50px;
}

.home4 {
  background-color: #FEFEFE;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
}


.mediacontent {
  background-color: #000000;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;

}

.case {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>