
require('dotenv').config();
const API_URL = process.env.VUE_APP_API_URL;
//const PUBLIC_KEY = process.env.VUE_APP_PUBLIC_KEY;
//const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY;
/*
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(API_URL);
*/
const Web3 = require('web3')

let web3 = new Web3(Web3.givenProvider || API_URL);

const contractNft = require("../../artifacts/contracts/Auth.sol/Auth.json");

const contractAddress = "0x89C0f4750C4cAD259f3eabEBC657d2A3a3102cB5";

const nftContract = new web3.eth.Contract(contractNft.abi, contractAddress);
const hash = localStorage.getItem('hash') || '';
const eth_add = localStorage.getItem('eth_add') || '';
export const nft = {
  namespaced: true,
  state: {
    walletAddress: eth_add,
    message: '',
    hash: hash,
    loggedIn: false,
    authorized: false,
  },
  actions: {
    async connectWallet({ commit }) {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          commit('connectWallet', addressArray[0]);
          commit('setMessage', `Connected to ${addressArray[0]}`)
          localStorage.setItem("eth_add", addressArray[0]);
        } catch (err) {
          console.log(err.message);
          commit('setMessage', err.message)
        }
      } else {
        commit('setMessage', "You must install Metamask 🦊, a virtual Ethereum wallet, in your browser.");
        console.log("You must install Metamask");
      }
    },
    async getCurrentWallet({ commit }) {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_accounts",
          });
          if (addressArray.length > 0) {
            commit('connectWallet', addressArray[0]);
            localStorage.setItem("eth_add", addressArray[0]);
          }
        } catch (err) {
          console.log(err.message);
        }
      }
    },
    async listenContract({ commit }) {
      nftContract.events.LoginSuccess({}, (error, data) => {
        if (error) {
          commit('setMessage', error.message)
        } else {
          console.log(data);
        }
      })
    },
    async login({ commit }, payload) {

      if (!window.ethereum) {
        commit('setMessage', "You must install Metamask 🦊, a virtual Ethereum wallet, in your browser.");
        return;
      }

      await nftContract.methods.login(payload.password).send({
        'from': window.ethereum.selectedAddress,

      })
        .on("receipt", (receipt) => {
          const _hash = receipt.events.LoginSuccess.returnValues.hash;
          commit('loginSuccess', _hash)
          commit('setMessage', `Login completed ${_hash}`)
          localStorage.setItem("hash", _hash);


        })
        .catch(function (error) {
          console.log(error);
          commit('setMessage', error);
        })


    },
    async register({ commit }, payload) {

      if (!window.ethereum) {
        commit('setMessage', "You must install Metamask 🦊, a virtual Ethereum wallet, in your browser.");
        return;
      }

      await nftContract.methods.register(payload.username, payload.password)
        .send({
          'from': window.ethereum.selectedAddress,
          //'gas': 300000
        })
        .on("receipt", (receipt) => {
          commit('setMessage', `Registration completed ${receipt.events.RegisterSuccess.returnValues._address}`)
        })
        .catch(function (error) {
          console.log(error);
          commit('setMessage', error);
        })



    },
    async checkHash({ state, commit }) {


      let valid = false;

      const walletAddress = state.walletAddress;
      const _hash = state.hash;
  

      if (_hash === '' || walletAddress === '') {
        valid = false;
      } else {
        valid = await nftContract.methods.checkHash(walletAddress, web3.eth.abi.encodeParameters(['bytes32'], [_hash])).call();
      }
  
      if (valid) {
        commit('loginSuccess', state.hash)
      }

      commit('authorize', valid);
      return valid;
    },
    async logout({ commit }) {

      await nftContract.methods.logout()
        .send({
          'from': window.ethereum.selectedAddress,
          //'gas': 300000
        })
        .on("receipt", (receipt) => {
          commit('setMessage', `Logged out ${receipt.events.UserDeleted.returnValues._address}`)
          commit('logOut')
          commit('authorize', false);
          localStorage.removeItem("hash");
          localStorage.removeItem("eth_add");
        })
        .catch(function (error) {
          console.log(error);
          commit('setMessage', error);
        })

    },
    setMessage({ commit }, payload) {
      commit('setMessage', payload);
    }
  },
  mutations: {
    loginSuccess(state, payload) {
      state.hash = payload;
      state.loggedIn = true;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    connectWallet(state, payload) {
      state.walletAddress = payload;
    },
    authorize(state, payload) {
      state.authorized = payload;
    },
    logOut(state) {
      state.loggedIn = false;
      state.hash = '';
    }
  },
  getters: {
    getMessage(state) {
      return state.message;
    },
    loadedWallet(state) {
      return state.walletAddress;
    },
    loggedIn(state) {
      return state.loggedIn;
    }

  }
};