import NoteService from '../services/note.service';

import EventBus from "../common/EventBus";


export const note = {
  namespaced: true,
  state: {
    noteArray: [],
    noteImgUrl: '',
    tag: []
  },
  actions: {
    createNote({ commit }, payload) {
      return NoteService.create(payload).then(
        data => {
          commit('createNote', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    loadNote({ commit }) {
      return NoteService.loadNote().then(
        data => {
          const obj = data;
          const notes = [];
          for (let key in obj) {
            notes.push({
              id: obj[key]._id,
              title: obj[key].title,
              content: obj[key].content,
              author: obj[key].author,
              date: obj[key].date,
              isPublic: obj[key].isPublic,
              imageUrl: obj[key].imageUrl,
              tag: obj[key].tag,
              todo: obj[key].todo,
            })
          }
          commit('fetchedNote', notes);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          return Promise.reject(error);
        }
      );
    },
    deleteNote({ commit }, payload) {
      return NoteService.delete(payload).then(
        data => {
          commit('deleteNote', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    loadTag({ commit }) {
      return NoteService.loadTag().then(
        data => {
          const obj = data;
          const tags = [];
          for (let key in obj) {
            tags.push(obj[key].name);
          }
          commit('fetchedTag', tags);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          return Promise.reject(error);
        }
      );
    },
    uploadImg({ commit }, payload) {
      return NoteService.upload(payload).then(
        data => {
          commit('uploadImg', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    addTodo({ commit }, payload) {
      return NoteService.addTodo(payload).then(
        data => {
          commit('addTodo', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    validateTodo({ commit }, payload) {
      return NoteService.validate(payload).then(
        data => {
          commit('validateTodo', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
    editNote({ commit }, payload) {
      return NoteService.edit(payload).then(
        data => {
          commit('editNote', data);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    createNote(state, payload) {
      state.noteArray.push(payload);
      state.tag.push(payload.tag);
    },
    fetchedNote(state, payload) {
      state.noteArray = payload;
    },
    fetchedTag(state, payload) {
      state.tag = payload;
    },
    uploadImg(state, payload) {
      state.noteImgUrl = payload;
    },
    deleteNote(state, payload) {
      const note = state.noteArray.find(note => {
        return note.id === payload.id
      })
      const index = state.noteArray.indexOf(note);
      state.noteArray.splice(index, 1);
    },
    addTodo(state, payload) {
      const note = state.noteArray.find(note => {
      return note.id === payload.noteId
    })
    if (!note.todo) {
      note.todo = []
    }
    const todo = {
      id: payload.id,
      content: payload.content,
      author: payload.author,
      done: payload.done
    }

    note.todo.push(todo)
    },
    validateTodo(state, payload) {
      const note = state.noteArray.find(note => {
      return note.id === payload.noteId
    })
    const todo = note.todo.find(todo => {
      return todo._id === payload.id
    })
    todo.done = payload.done
    todo.date = payload.date
    },
    editNote(state, payload) {
      const note = state.noteArray.find(note => {
      return note.id === payload.id
    })
    note.content = payload.content;
    },
  },
  getters: {
    loadedNotes (state) {
    //return state.loadedMatter.reverse()
      return state.noteArray.sort((noteA, noteB) => {
        const da = new Date(noteA.date)
        const db = new Date(noteB.date)
        if (da === db) return 0
  
        return da < db ? 1: -1
      });
    },
    loadedNote (state) {
      return (elementId) => {
        return state.noteArray.find((element) => {
          return element.id === elementId
        })
      } 
    },
    loadedImgUrl (state) {
    //return state.loadedMatter.reverse()
      return state.noteImgUrl;
    },
    loadedTags (state) {
      return state.tag.sort()
        
    },

  }
};