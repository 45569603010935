<template>
    <div class="main">
      <b-navbar toggleable="md" type="light" :sticky="true">
      <b-navbar-brand class="mx-2" href="/">
        <!--<img src="logo.png" width="50" height="20" class="d-inline-block"> -->
        Archetype
      </b-navbar-brand>
  
  
        <b-navbar-nav>
  
          <b-nav-item>
          <ChatModal></ChatModal>
          </b-nav-item>
        </b-navbar-nav>
      
  
        <b-navbar-nav class="ml-auto">
        <b-button @click="connectWallet" size="sm" variant="nature1">
              <b-icon icon="bag-fill"></b-icon>
              {{ wallet }}
         
        </b-button>
  
       </b-navbar-nav>  
  
      
    </b-navbar>
  
      <div class="container text-dark">
      <b-row>
        <b-col md="6" xs="12">             
              <h3>HUB - {{ wallet }}</h3>
              <div class="headline"></div>
            
  
         
          
        </b-col>
  
        <b-col md="6" xs="12">
          
        </b-col>
      </b-row>
      </div>
   
    </div>
  </template>
  
  <script>
  
  import ChatModal from '@/components/ChatModal.vue';
  
  export default {
    name: 'Home',
    components: {
      ChatModal
    },
    data() {
      return {
        content: ''
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.nft.loggedIn;
      },
      walletAddress() {
        return this.$store.getters['nft/loadedWallet'];
      },
      wallet() {
        if (this.walletAddress === '') {
          return 'Wallet'
        } else {
          return String(this.walletAddress).substring(0, 4) + "..." + String(this.walletAddress).substring(40);
        }
      },
    },
    mounted() {
  
    },
    methods: {
      connectWallet: function () {
        this.$store.dispatch('nft/connectWallet');
      },
    }
  };
  </script>
  <style scoped>
  .spacer {
    margin-top: 40px;
  }
  .headline {
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .liste {
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  </style>