import ArticleService from '../services/article.service';


export const article = {
  namespaced: true,
  state: {
    articleArray: [],
    articleImgUrl: '',
    articleSectionImgUrl: '',
    sectionId: [],
    footprint: []
  },
  actions: {
    
    loadArticle({ commit }) {
      return ArticleService.loadArticle().then(
        data => {
          const obj = data;
          const medias = [];
          for (let key in obj) {
            medias.push({
              id: obj[key]._id,
              title: obj[key].title,
              section: obj[key].section,
              author: obj[key].author,
              date: obj[key].date,
              footprint: obj[key].footprint,
              imageUrl: obj[key].imageUrl,
              comment: obj[key].comment,
              like: obj[key].like,
              likes: obj[key].likes
            })
          }
          commit('fetchedArticle', medias);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          return Promise.reject(error);
        }
      );
    },
    loadFootprint({ commit }) {
      return ArticleService.loadFootprint().then(
        data => {
          const obj = data;
          const footprints = [];
          for (let key in obj) {
            footprints.push(obj[key].name);
          }
          commit('fetchedFootprint', footprints);
          return Promise.resolve(data);
        },
        error => {
          console.log(error)
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {

    fetchedArticle(state, payload) {
      state.articleArray = payload;
    },
    fetchedFootprint(state, payload) {
      state.footprint = payload;
    },
  },
  getters: {
    loadedArticles (state) {
    //return state.loadedarticle.reverse()
   
      return state.articleArray.sort((articleA, articleB) => {
        const da = new Date(articleA.date)
        const db = new Date(articleB.date)
        if (da === db) return 0
  
        return da < db ? 1: -1
      });
    },
    loadedArticle (state) {
      return (elementId) => {
        return state.articleArray.find((element) => {
          return element.id === elementId
        })
      } 
    },
    loadedFootprint (state) {
      return state.footprint.sort()
        
    },

  }
};