import api from "./api2";
import TokenService from "./token.service";

class AuthService {
  logout() {
    TokenService.removeUser();
  }

  smartContractRegister(data) {
    return api.post("/auth/authcontract/register", {
      address: data.address,
      hash: data.hash
    }).then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  smartContractLogin(data) {
    return api.post("/auth/authcontract/login", {
      address: data.address,
      hash: data.hash
    }).then((response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }
      console.log(response.data);
      return response.data;
    });
  }

  editProfile(user) {
    return api
      .post("/auth/editprofile", {
        id: user.id,
        username: user.username,
        email: user.email,
        imageUrl: user.imageUrl
      })
      .then(response => {

        let loadedUser = localStorage.getItem('user');

        loadedUser = loadedUser ? JSON.parse(loadedUser) : {};

        loadedUser['username'] = response.data.username;
        loadedUser['email'] = response.data.email;
        loadedUser['imageUrl'] = response.data.imageUrl;

        localStorage.setItem('user', JSON.stringify(loadedUser));

        console.log(response.data);
        return response.data;
      });
  }

  upload(matter) {
    let formData = new FormData();

    formData.append("file", matter);

    return api
      .post("/matter/img", formData, 
      {
        headers: {
          "Content-Type": "multipart/form-data" }

      })
      .then(response => {
        return response.data;
      });
  }
  saveCredentials(credentials) {
    return api
      .post("/auth/twitter/credentials", credentials)
      .then(response => {
        return response.data;
      });
  }
  getCredentials() {
    return api
      .get("/auth/twitter/credentials")
      .then(response => {
        return response.data;
      });
  }
  deleteUser() {
    return api
      .delete("/auth/delete")
      .then(response => {
        TokenService.removeUser();
        return response.data;
      });
  }
}

export default new AuthService();