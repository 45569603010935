import Vue from 'vue'
import Vuex from 'vuex'

import { media } from './media.module';
import { article } from './article.module';
import { nft } from './nft.module';
import { ai } from './ai.module';
import { auth } from './auth.module';
import { twitter } from './twitter.module';
import { note } from './note.module';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
	media,
  article,
  nft,
  ai,
  auth,
  twitter,
  note
  }
})
