<template>

    <div class="todo">
        <div>ToDo</div>
        <div class="mt-3 mb-3" v-for="todo in note.todo" :key="todo._id">
            <b-row>
                <b-col cols="10">
                    {{ todo.content }}

                </b-col>
                <b-col cols="2">
                    <b-form-checkbox @change="validateTodo(todo._id)" v-model="box" :value="todo.done"
                        :disabled="loading">

                    </b-form-checkbox>
                </b-col>
            </b-row>
        </div>
        <form v-if="currentUser" name="form" @submit.prevent="createNode">
                <b-row>
                    <b-col cols="10">
                        <b-form-input id="todo" v-model="content" placeholder="..."></b-form-input>
                    </b-col>
                    <b-col cols="2">
                        <b-button size="sm" type="submit" class="btn btn-block mt-2" :disabled="loading">+</b-button>
                    </b-col>
                </b-row>
              
              
            </form>
    </div>

</template>
  
<script>
export default {
    props: ['note'],
    data() {
        return {
            content: '',
            box : false,
            loading: false
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        createNode: function () {
            this.loading = true;
            if (!this.content) {
                return;
            }
                const todoData = {
                    id: this.note.id,
                    content: this.content,
                    done: this.box
                }
            
                this.$store.dispatch('note/addTodo', todoData)
                this.loading = false;
            

        },
        validateTodo: function (id) {
            this.loading = true;
            const todoData = {
                id: id,
                noteId: this.note.id
            }
            this.$store.dispatch('note/validateTodo', todoData)
            this.loading = false;
        },

    }
}
</script>